import React from "react";
import { Link } from "react-router-dom";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

const Eroor  = function error_4_0_4 ({ t }) {
  return (
    404
  );
}

export default withTranslation()(Eroor);
